<template>
  <div class="team">
    <section class="section">
      <div class="container">
        <div class="columns is-centered">
          <figure>
            <div class="hero-body">
              <p class="title" style="">Creator</p>
            </div>
          </figure>
        </div>
      </div>
    </section>
    <div class="container about">
      <div class="columns is-centered">
        <div class="columns">
          <div class="column" align="center">
            <img src="../assets/images/erkan_akgul.jpg" alt="erkan_akgul" />
          </div>
          <div class="column person">
            <p class="person-title">Erkan AKGUL</p>
            <p class="person-subtitle">Creator</p>
            <p class="person-content">
              Erkan is an Expert Designer with 14 year-experience. His
              background is related to vehicle and internal combustion engine
              design engineering but he is always interested in any kind of
              design. His newest interest is software.
            </p>
            <p class="person-social">
              <a href="https://www.linkedin.com/in/erkanakgul/" target="_blank">
                <b-icon pack="fab" icon="linkedin" size="is-large"> </b-icon>
              </a>
              <a href="https://instagram.com/erkanakgul/" target="_blank">
                <b-icon pack="fab" icon="instagram" size="is-large"> </b-icon>
              </a>
              <a href="https://twitter.com/erknkgl/" target="_blank">
                <b-icon pack="fab" icon="twitter" size="is-large"> </b-icon>
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="container about">
      <div class="columns is-centered">
        <div class="columns">
          <div class="column" align="center">
            <img src="../assets/images/ali_akgul.png" alt="ali_akgul" />
          </div>
          <div class="column person">
            <p class="person-title">Ali AKGUL</p>
            <p class="person-subtitle">Business Development</p>
            <p class="person-content">
              Ali is an Electrical and Electronics Engineer with experience in
              field engineering, project management and business development.
              Currently, he works in the energy sector.
            </p>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "Creator",
  title: "porwod - Creator",
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/_variables.scss";

.hero-body {
  text-align: center;
  font-family: $head-font;
  font-weight: 400;
  font-style: normal;
}

.team {
  margin-block-end: 8rem;
}

img {
  border-radius: 15px 50px 30px;
  width: 300px;
  height: auto;
}

.person {
  margin: 3rem 0 0 3rem;

  &-title {
    font-family: $head-font;
    font-weight: 600;
    font-style: normal;
    font-size: 2rem;
  }

  &-subtitle {
    font-family: $head-font;
    font-weight: 400;
    font-style: normal;
    font-size: 1.75rem;
  }

  &-content {
    font-family: $desc-font;
    font-weight: 400;
    font-style: normal;
    font-size: 1.25rem;
  }

  &-social {
    margin-block-start: 1rem;
    margin-inline-start: -7.75px;
  }
}

.about {
  margin-block-end: 6rem;
}

.columns {
  margin-right: 0;
}

.head-description {
  font-family: $desc-font;
  font-weight: 400;
  font-style: normal;
}
</style>
